import { useQuery } from '@apollo/react-hooks'
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Box,
} from '@material-ui/core'
import fileDownload from 'js-file-download'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from '@reach/router'
import { gql } from 'apollo-boost'
import ContactRole from 'components/ContactRole'
import ErrorMessage from 'components/ErrorMessage'
import ResultsTablePagination from 'components/ResultsTablePagination'
import SkeletonTable from 'components/SkeletonTable'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import constants from 'utils/constants'
import { useQueryParams } from 'utils/hooks'
import getInitials from 'utils/getInitials'
import { jsonToCsv, jsonToXlsx } from 'utils/exportUtils'
import { assignLatestInfoToItems } from 'utils/enrollmentsHelper'
import ExportMenuList from 'components/ExportMenuList'

export const GET_CONTACTS = gql`
  query ($filter: ContactFilterInput!) {
    contactsSearch(filter: $filter) {
      items {
        id
        slug
        fullName
        firstName
        lastName
        email
        title
        phoneNumber
        type
        enrollment {
          id
          project {
            id
            title
            year
          }
          school {
            id
            name
          }
        }
      }
      total
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  row: {
    whiteSpace: 'nowrap',
  },
}))

const Results = (props) => {
  const { location } = props
  const classes = useStyles()
  const [loadingExport, setLoadingExport] = useState(false)
  const [exportPage, setExportPage] = useState(1)
  const { queryVariables, page, searchString, rowsPerPage } = useQueryParams(location)

  // Request data
  const { data, loading, error, client } = useQuery(GET_CONTACTS, {
    variables: { filter: queryVariables },
  })

  // SkeletonView
  if (loading)
    return (
      <SkeletonTable
        searchString={searchString}
        rowsPerPage={rowsPerPage}
        columns={constants.columns.contacts}
      />
    )

  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  const { items: contacts, total } = data.contactsSearch

  const handleExport = async (exportType) => {
    setLoadingExport(true)

    let items = []
    for (let i = 0; i < Math.ceil(total / rowsPerPage); i++) {
      const response = await client.query({
        query: GET_CONTACTS,
        variables: {
          filter: {
            ...queryVariables,
            page: i,
            rowsPerPage,
            sort: {
              order: 'asc',
              property: 'name',
            },
          },
        },
        fetchPolicy: 'network-only',
      })

      if (response?.data?.contactsSearch?.items) {
        items = items.concat(response.data.contactsSearch.items)
      }

      setExportPage(i + 1)
    }

    const updatedItems = items

    if (exportType === constants.exportTypes.csv) {
      const columns = ['id', 'firstName', 'lastName', 'email', 'title', 'type', 'phoneNumber']

      const csv = await jsonToCsv(updatedItems, columns)
      fileDownload(csv, 'contacts.csv', 'text/csv')
    } else if (exportType === constants.exportTypes.xls) {
      const columns = [
        { label: 'id', value: 'id' }, // Top level  data
        { label: 'firstName', value: 'firstName' },
        { label: 'lastName', value: 'lastName' },
        { label: 'email', value: 'email' },
        { label: 'title', value: 'title' },
        { label: 'type', value: 'type' },
        { label: 'phoneNumber', value: 'phoneNumber' },
        {
          label: 'school',
          value: (row) => getContactsSchool(row.enrollment),
        },
        {
          label: 'latestEnrollmentYear',
          value: (row) => getLatestEnrolledProject(row.enrollment),
        },
      ]

      const settings = {
        sheetName: 'contacts', // The name of the sheet
        fileName: 'contacts', // The name of the spreadsheet
        extraLength: 3, // A bigger number means that columns should be wider
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      }

      jsonToXlsx(columns, updatedItems, settings, true)
    }

    setLoadingExport(false)
    setExportPage(1)
  }

  function getContactsSchool(enrollment) {
    if (!enrollment || enrollment.length === 0) return 'Ikke tilknyttet skole'

    return enrollment.school.name
  }

  function getLatestEnrolledProject(enrollment) {
    if (!enrollment || enrollment.length === 0) return 'Ikke påmeldt'

    return enrollment.project.year
  }

  return (
    <div className={classes.root}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {total} Resultater. Side {page + 1} av {Math.ceil(total / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader
          title={searchString === '' ? 'Alle personer' : `Søk på ${searchString}`}
          action={
            <Grid container direction="row" justify="flex-end" alignItems="stretch" spacing={2}>
              <Grid item>
                <Box visibility={loadingExport ? 'visible' : 'hidden'} className={classes.box}>
                  <Typography color="textSecondary" variant="body2">
                    Lastet ned {exportPage} av {Math.ceil(total / rowsPerPage)} side
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <ExportMenuList
                  onSelect={handleExport}
                  loadingExport={loadingExport}
                ></ExportMenuList>
              </Grid>
            </Grid>
          }
        />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {constants.columns.contacts.map((column) => (
                      <TableCell key={column}>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contacts.map((item) => {
                    return (
                      <TableRow hover key={item.id} className={classes.row}>
                        <TableCell>
                          <div className={classes.nameCell}>
                            <Avatar className={classes.avatar} src={item.avatar}>
                              {getInitials(item.fullName)}
                            </Avatar>
                            <div>
                              <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/contacts/${item.slug}`}
                                variant="h6"
                              >
                                {item.fullName}
                              </Link>
                              <div>{item.email}</div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>{item.phoneNumber}</TableCell>
                        <TableCell>
                          <ContactRole>{item.type}</ContactRole>
                        </TableCell>
                        <TableCell>
                          {item.enrollment && item.enrollment.school && item.enrollment.school.name}
                        </TableCell>
                        <TableCell>
                          {item.enrollment &&
                            item.enrollment.project &&
                            item.enrollment.project.title}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <ResultsTablePagination
            location={location}
            page={page}
            total={total}
            rowsPerPage={rowsPerPage}
            queryVariables={queryVariables}
            client={client}
            query={GET_CONTACTS}
          />
        </CardActions>
      </Card>
    </div>
  )
}

Results.propTypes = {
  location: PropTypes.object,
}

export default Results
