import React from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress, Menu, MenuItem } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core/styles'
import constants from '../../utils/constants'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}))

const ExportMenuList = (props) => {
  const classes = useStyles()
  const { onSelect, loadingExport } = props
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (exportType) => {
    setAnchorEl(null)
    onSelect(exportType)
  }

  return (
    <div className={classes.root}>
      <Button
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        startIcon={loadingExport ? <CircularProgress color="secondary" size={16} /> : <SaveIcon />}
        disabled={loadingExport}
      >
        {loadingExport ? 'Eksporterer' : 'Eksporter'}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ horizontal: 'center' }}
        PaperProps={{
          style: {
            width: '8em',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleSelect(constants.exportTypes.csv)
          }}
        >
          CSV
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSelect(constants.exportTypes.xls)
          }}
        >
          XLS
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            handleSelect(constants.exportTypes.crisp)
          }}
        >
          Crisp
        </MenuItem> */}
      </Menu>
    </div>
  )
}

ExportMenuList.propTypes = {
  onSelect: PropTypes.func,
  loadingExport: PropTypes.bool,
}

export default ExportMenuList
